
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { BaseAPI } from '@app/shared/data/base.api';
import { User } from '@app/shared/data/user/user.models';
import { CreditCard } from '@app/shared/data/credit-card/credit-card.models';
import { Plan } from '@app/shared/data/plan/plan.models';
import { Coupon } from '@app/shared/data/coupon/coupon.models';
import { AuthService } from '@app/core/services/auth.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ConnectSPApiOriginEnum } from '../review-request/review-request.models';
import { CustomURLEncoder } from '@app/shared/data/product-manager/product-manager.api';


@Injectable()
export class UserAPI extends BaseAPI {
  constructor(
    protected http: HttpClient,
    private i18n: I18n
  ) {
    super();
  }

  public refreshToken(): Observable<any> {
    return this.http.get(this.constructFullUrl('api/auth/token'), { params: { _tokenInterceptor: 'false' }}).pipe(map(d => new User(d)));
  }

  public getApiToken(): Observable<any> {
    return this.http.get(this.constructFullUrl('api/auth/api-token'), { params: { _tokenInterceptor: 'false' } });
  }

  public attemptAuth(data): Observable<any> {
    const loginUrl = 'api/auth/login';
    return this.http.post(this.constructFullUrl(loginUrl), data, { params: { _tokenInterceptor: 'false' } }).pipe(
      map(d => new User(d)));
  }

  public getCoupon(coupon: Coupon) {
    const params: HttpParams = new HttpParams({encoder: new CustomURLEncoder()})
      .set('feature', coupon.feature ? coupon.feature : null)
      .set('plan', coupon.plan || null)
      .set('promotion', coupon.promotion || null);

    return this.http.get(this.constructFullUrl(`api/auth/coupons/${encodeURIComponent(coupon.code)}`), {params}).pipe(
               catchError(e => observableThrowError(e.error)),
               map(data => new Coupon(data)));
  }

  public sendPasswordResetEmail(data): Observable<any> {
    return this.http.post(this.constructFullUrl('api/auth/password/email'), data, { params: { _tokenInterceptor: 'false' } });
  }

  public resetPassword(data): Observable<any> {
    return this.http.post(this.constructFullUrl('api/auth/password/reset'), data, { params: { _tokenInterceptor: 'false' } });
  }

  public attemptRegistration(data): Observable<User> {
    return this.http.post(this.constructFullUrl('api/auth/register'), data).pipe(
      map(d => new User(d)));
  }

  public resendVerificationEmail(user: User) {
    return this.http.post(this.constructFullUrl('api/auth/resend-verify-email'), {}).pipe(
      map(x => ({message: this.i18n('Verification email successfully sent.')})),
      catchError((e) => {
        return observableThrowError(
          {
              message: this.i18n('Verification email not sent. ({{message}})', {message: e.error.error.message}),
              code: e.error.error.error_code
          }
        );
      }),);
  }

  public attemptLogout(): Observable<any> {
    return this.http.get<User>(this.constructFullUrl('api/auth/logout')).pipe(
      map((data) => {
        return Object.keys(data).length === 0
          ? data
          : new User(data);
      }));
  }

  public limits(): Observable<any> {
    return this.http.get(this.constructFullUrl('api/users/limits'));
  }

  public trialAccountTimeLeft(): Observable<any> {
    return this.http.get(this.constructFullUrl('api/users/trial-account-time-left'));
  }

  public fetchUserData(): Observable<any> {
    return this.http.get(this.constructFullUrl('api/users/0')).pipe(map(d => new User(d)));
  }

  public switchCredentials(credentialId: number): Observable<any> {
    return this.http.post(this.constructFullUrl('api/auth/switch-credential'), {
      credential_id: credentialId
    }).pipe(map(d => new User(d)));
  }

  public addCreditCard(token, def = true): Observable<any> {
    return this.http.post(this.constructFullUrl('api/billing/cards'), {
      'token'  : token,
      'default': def
    }).pipe(map(d => new CreditCard(d)));
  }

  public subscribeToPlan(plan: Plan, coupon: Coupon = null): Observable<any> {
    return this.http.post(this.constructFullUrl('api/billing/subscriptions'), {
      plan_id: plan.id,
      coupon : coupon ? coupon.code : null
    });
  }

  public updateProfile(data, userId: number): Observable<any> {
    return this.http.put(this.constructFullUrl('api/users/' + userId), data).pipe(
      map(d => new User(d)));
  }

  public coachingOfferViewed(userId: number): Observable<any> {
    return this.http.post(this.constructFullUrl('api/users/' + userId + '/coaching-offer-viewed'), {});
  }

  public changeLocale(locale: String): Observable<any> {
    return this.http.post(this.constructFullUrl('api/users/change-locale'), {
      locale: locale
    });
  }

  public spaInit(credentialId: number, origin: ConnectSPApiOriginEnum) {
    let params: HttpParams = new HttpParams()
      .set('credential_id', credentialId)
      .set('origin', origin);

    return this.http.get(this.constructFullUrl('api/auth/spa-init'), { params });
  }

  public getSpaExternalLink(sellingPartnerId: string, marketplaceId: string) {
    let params: HttpParams = new HttpParams()
      .set('selling_partner_id', sellingPartnerId)
      .set('marketplace_id', marketplaceId.toString());

    return this.http.get(this.constructFullUrl('api/auth/spa-generate-external-link'), { params });
  }

  public spaRedirect(state: string, spApiOauthCode: string, sellingPartnerId: string) {
    let params: HttpParams = new HttpParams()
      .set('state', state)
      .set('spapi_oauth_code', spApiOauthCode)
      .set('selling_partner_id', sellingPartnerId)

    return this.http.get(this.constructFullUrl('api/auth/spa-redirect'), { params });
  }
}
