// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  hmr: false,
  reduxEnhancer: false,

  // Ahead-of-Time compiler
  AOT_COMPILER: true,

  // localStorage
  LOCALSTORAGE_KEY: 'ST',

  LOCALIZATION: 'en',

  // API
  API_URL: 'http://api.seller-tools.test',
  GRAPHS_API_URL: 'http://graphs.seller-tools.test',
  SAMURAI_SELLER_URL: 'https://preview.junglerabbit.com',
  API_DOCS_URL: 'https://data.seller.tools/api/docs',
  KEYWORD_INSIGHTS_EXTENSION_URL: 'https://chrome.google.com/webstore/detail/sellertools-chrome-extens/hjlmldddmfhmlignbidllopepcpnikln',
  MANY_CHAT_EXTERNAL_URL: 'https://seller.tools/automation/manychat-flows/',

  // WEBSCOCKET CONFIG
  ECHO_HOST: 'http://api.seller-tools.test:6001',

  // LEAD DYNO CONFIG
  LEAD_DYNO_SECRET: '',
  LEAD_DYNO_DOMAIN: 'https://app.seller.tools',

  // GOOGLE ANALYTICS
  GA_KEY: '',
  GTAG_ID: '',
  GTAG_KEY: '',

  // ProfitWell
  PROFIT_WELL_ID: '',

  // PAYPAL
  PAYPAL_CLIENT_ID: 'AU3qgUDQxda-5XpxzHKCDutIrs6OO_vB-Lf7ZnCKsRx-gXrdt0imkDPzYcz8VK7JKR-F6YwH5X3ueXTA',

  // MWS
  MWS_DEVELOPER_NUMBER_NA: '',
  MWS_DEVELOPER_NUMBER_EU: '',

  POWER_TIME_LIMIT: 1,
  REINDEX_TIME_LIMIT: 1,

  DEPLOYED_AT: null,

  BULK_BUFFER_SIZE: 20,

  LOG_ROCKET_APP_ID: 'eh0tde/st',
  FULL_STORY_ORGANIZATION_ID: 'R40RR',

  KWMANAGER_PER_PAGE: 30,
  PER_PAGE: 30,
  KW_ADD_LINE_LIMIT: 200,

  MANYCHAT_WIDGET_ID: 6388433,

  PRIZM_MARKETPLACES: ['amazon.com'],

  FLOW_REFRESH_TIMEOUT: 30000,

  R2A_ASIN_LIMIT: 12,
  R2A_ASIN_CASCADE_LIMIT: 3,
  R2A_BULK_ADD_KEYWORDS_LIMIT: 2000,

  R2A_TIMEOUT: 10 * 60 * 1000, //ms
  R2A_REQUEST_INTERVAL: 20 * 1000, //ms

  R2A_COMPARE_RANK_REFRESH_INTERVAL: 30 * 1000, //ms
  R2A_COMPARE_RANK_REFRESH_TIMEOUT: 600 * 1000, //ms

  INTERCOM_APP_ID: 'tm5zvwra',

  SELLER_CENTRAL_APP_ID: 'amzn1.sellerapps.app.bae61df2-2826-4531-a8ad-a2f9387c0bb7',

  CONFIG_CAT_BASE_URL: null, // Let config-cat choose url
  CONFIG_CAT_SDK_KEY: '',
  CONFIG_CAT_LOGGING_LEVEL: 3, // Info
  CONFIG_CAT_POLL_INTERVAL: 10, // Poll interval in seconds

  STRIPE_PUBLISHABLE_KEY: null,
  PLAID_ENVIRONMENT: null,
  PLAID_CLIENT_NAME: null,
  PLAID_PUBLIC_KEY: null,
  CHAT_BASE_URL: 'http://cm.service.stdev/r',

  RUDDER_STACK_URL: '',
  RUDDER_STACK_KEY: '',
  RUDDER_STACK_CONFIG_URL: null,

  // minimal number of competitors to analyse keywords
  PRODUCT_MANAGER_MIN_COMPETITORS: 4,

  // URLs
  NEW_PRICING_URL: 'https://help.seller.tools/en/articles/5490421-updated-pricing-in-seller-tools-2021-august',
  FULL_PRICING_URL: 'https://seller.tools/pricing/',
  CANCELLATION_POLICY_URL: 'https://seller.tools/billing/',

  // Sentry
  SENTRY_DSN: null,
  SENTRY_ENVIRONMENT: 'dev',
  SENTRY_SAMPLE_RATE: 1.0,
  SENTRY_SAMPLE_ORIGINS: [],

  // SmartLook
  SMARTLOOK_KEY: null,

  // C6 Navbar
  C6_NAVBAR_URL: 'https://dev-navbar.carbon6.io/dev/c6-navbar.js',
  // C6_NAVBAR_URL: 'http://localhost:8081/main.js',
  C6_APPLICATION_ID: 'SELLER_TOOLS',

  // C6 attribution script
  C6_ATTRIBUTION_SCRIPT_URL: 'https://bi.carbon6.io/static/js/bi.js',
  APPLICATION_NAME: 'SellerTools - Dev',

  // C6 Auth0
  AUTH0_DOMAIN: 'dev-login.carbon6.io',
  AUTH0_CLIENT_ID: 'xxJazP5e9ndFrQpWG9242lXRblbQ0KDH',
  AUTH0_AUDIENCE: 'https://dev.app.carbon6.io',

};
