
import {empty as observableEmpty, throwError as observableThrowError, of as observableOf,  Observable, combineLatest, BehaviorSubject, of } from 'rxjs';

import {catchError, mergeMap, switchMapTo, tap, take, filter,  map, switchMap } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { NgRedux, select } from '@angular-redux/store';
import { AppState } from '@app/shared/data/app-state.model';
import { UsersActions } from '@app/shared/data/user/user.actions';
import { User, UserBackedStateEnum, UserState } from '@app/shared/data/user/user.models';
import { Credential } from '@app/shared/data/credential/credential.models';
import { UserAdmin } from '@app/shared/data/admin/user/user-admin.models';
import { UserAdminActions } from '@app/shared/data/admin/user/user-admin.actions';
import { Router } from '@angular/router';
import { UserAPI } from '@app/shared/data/user/user.api';
import { Coupon } from '@app/shared/data/coupon/coupon.models';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';



@Injectable()
export class AuthService {
  /**
   *
   * @type {any}
   */
  private data = null;
  private isRefreshingToken = false;

  activeCredential: Credential;
  token: string;

  @select(['user', 'data', 'token']) token$: BehaviorSubject<string>;
  @select(['user', 'data', 'active_credential']) activeCredential$: BehaviorSubject<number>;
  public hasSession$ = 
    this.getUser$().pipe(map((user: any) => {
      return Boolean(user?.id);
    }));

  constructor(
    private injector: Injector,
    private ngRedux: NgRedux<AppState>,
    private router: Router,
    private userApi: UserAPI,
  ) {
    this.ngRedux.select('user')
      .subscribe((data) => {
        this.data = data;
      });
  }

  /**
   *
   * @returns {boolean}
   */
  private hasUser() {
    return this.data !== null && this.getUser().id !== undefined;
  }

  /**
   * @returns {User}
   */
  public getUser(): User {
    return this.data?.data;
  }

  /**
   * @return {Observable<User>}
   */
  public getUser$() {
    return this.ngRedux.select(['user', 'data']);
  }

  /**
   * @returns {any}
   */
  public getUserId() {
    return this.getUser().id;
  }

  /**
   * @returns {boolean}
   */
  public isAuthenticated(): boolean {
    return this.hasUser();
  }

  public getUserState(state: UserBackedStateEnum): UserState {
    if (!Boolean(this.getUser().states)) {
      return null;
    }
    return this.getUser().states.find((userState: UserState) => userState.state === state);
  }

  /**
   * @return {any}
   */
  public isImpersonating(): boolean {
    return this.getUser().isImpersonating();
  }

  /**
   * @returns {boolean}
   */
  public hasFinishedRegistration(): boolean {
    return this.getUser().registration_finished_at !== null;
  }

  /**
   * @returns {boolean}
   */
  public hasActiveCredential(): boolean {
    return !!this.getUser().active_credential;
  }

  /**
   * @returns {boolean}
   */
  public isActive(): boolean {
    return !!this.getUser().isActive();
  }

  /**
   * TODO: fire only once
   *
   * @returns {Observable<Credential>}
   */
  public activeCredentials$(): Observable<Credential> {
    return this.ngRedux.select(['user', 'data'])
      .pipe(
        filter(u => !!u),
        map((u: User) => u.allCredentials?.find(a => a.id === u.active_credential)),
        filter(val => !!val),
        tap(val => this.activeCredential = val),
        map(val => new Credential(val))
      );
  }

  /**
   * Returns current active credential
   * @returns {Credential}
   */
  public getActiveCredential(): Credential {
    const activeCredentialId = this.getUser().active_credential;
    if (!activeCredentialId) {
      return null;
    }

    const cred = this.data['data']['allCredentials']
      .find(credential => credential.id === activeCredentialId);

    return new Credential(cred);
  }

  /**
   * @returns {boolean}
   */
  public ownerHasPermission(permission: string): boolean {
    return this.hasActiveCredential() && this.getUser()?.hasPermission?.apply(this.getActiveCredential()?.owner, [permission]);
  }

  /**
   * @returns Observable<boolean>
   */
  public ownerHasPermission$(permission: string): Observable<boolean>  {
    return this.getOwner$().pipe(
      map((u: User) => u.hasPermission(permission, true))
    );
  }

  /**
   * @returns Observable<User>
   */
  public getOwner$(): Observable<User> {
    return this.getUser$().pipe(
      map((u: User) => {
        if (this.hasActiveCredential()) {
          return new User(this.getActiveCredential()?.owner);
        }
        return u;
      }));
  }

   public getOwner(): User {
     if (this.hasActiveCredential()) {
       return this.getActiveCredential().owner;
     }

     return this.getUser();
  }

   public getCurrentOwnerCredentials$(): Observable<Credential[]> {
     return combineLatest([
      this.ngRedux.select(['user', 'data', 'allCredentials']),
      this.getOwner$()
     ]).pipe(map(([allCredentials, owner]: [Credential[], User]) => allCredentials.filter(credential => credential.owner.id === owner.id)));
  }

  /**
   * @returns {boolean}
   */
  public userHasPermission(permission: string): boolean {
    return this.getUser()?.hasPermission(permission);
  }

  /**
   * @returns {Observable<boolean>}
   */
  public userHasPermission$(permission: string): Observable<boolean> {
    return this.getUser$().pipe(map((u: User) => u.hasPermission(permission)));
  }

  private userHasAnyPermissions() {
    return this.getUser().permissions && this.getUser().permissions.length > 3;
  }

  /**
   * @returns {boolean}
   */
  public hasPermissions(ownerSlug: string, subAccountSlug?: string): boolean {
    let userHasPermission = true;
    if (subAccountSlug) {
      userHasPermission = this.isActiveUserOwnerActiveCredential() || this.userHasPermission(subAccountSlug);
    }
    return userHasPermission && this.ownerHasPermission(ownerSlug);
  }

  public isOwnerOrHasSubAccountPermission$(permission: string): Observable<boolean> {
    return this.getUser$().pipe(map(() => this.isOwnerOrHasSubAccountPermission(permission)));
  }

  public isOwnerOrHasSubAccountPermission(permission: string): boolean {
    return this.isActiveUserOwnerActiveCredential() || this.userHasPermission(permission);
  }

  /**
   * Check if token is currently set.
   *
   * @returns {boolean}
   */
  public hasToken() {
    return this.hasUser() && this.getAccessToken() != null;
  }

  /**
   * Check if user has verified his email address.
   *
   * @returns {boolean}
   */
  public hasVerified() {
    return this.hasUser() && this.data?.data.is_verified;
  }

  public storeToken(token: string): void {
    if (!token) {
      return;
    }

    localStorage.setItem('token', token);
  }

  public getAccessToken(): string {
    return localStorage.getItem('token');
  }

  public clearToken(): void {
    localStorage.removeItem('token');
  }

  /**
   * Determine if token is currently refreshing.
   *
   * @returns {boolean}
   */
  public isTokenRefreshing() {
    return this.data?._is_refreshing_token || this.isRefreshingToken;
  }

  /**
   * Determine if active credential switch is in progress.
   * @returns {boolean}
   */
  public isSwitchingActiveCredential() {
    return this.data?._is_switching_credential;
  }

  /**
   * Determine if token is currently refreshing.
   *
   * @returns {boolean}
   */
  public isAttemptingAuth() {
    return this.data?._is_attempting_auth;
  }

  /**
   * Determine if user is attempting logout.
   *
   * @returns {any}
   */
  public isAttemptingLogout() {
    return this.data?._is_attempting_logout;
  }

  /**
   * Determine if user is attempting registration.
   *
   * @return {any}
   */
  public isAttemptingRegistration() {
    return this.data?._is_attempting_registration;
  }

  /**
   *
   * @param data
   * @param force
   *
   * @returns {Promise<T>}
   */
  public attemptAuth(data: any, force: boolean = false): Promise<boolean> {
    if (!this.isAttemptingAuth()) {
      const actions = this.injector.get(UsersActions);

      if (force) {
        data.force = true;
      }

      //noinspection TypeScriptValidateTypes
      this.ngRedux.dispatch(actions.attemptAuth(data));
    }

    return new Promise((resolve, reject) => {
      this.ngRedux.select(['user', '_is_attempting_auth']).pipe(filter(loading => !loading),take(1),).subscribe(() => {
        const state = this.ngRedux.getState()['user'];

        if (state['data']['id'] !== undefined) {
          resolve(true);
        } else {
          resolve(state['_error']);
        }
      });
    });
  }

  /**
   * @param data
   * @return {Promise<T>}
   */
  public attemptRegistration(data: any): Promise<boolean> {
    if (!this.isAttemptingRegistration()) {
      const actions = this.injector.get(UsersActions);

      //noinspection TypeScriptValidateTypes
      this.ngRedux.dispatch(actions.attemptRegistration(data));
    }

    return new Promise((resolve, reject) => {
      this.ngRedux.select(['user', '_is_attempting_registration']).pipe(filter(loading => !loading),take(1),).subscribe(() => {
        const state = this.ngRedux.getState()['user'];

        if (state['data']['id'] !== undefined) {
          resolve(true);
        } else {
          resolve(state['_error']);
        }
      });
    });
  }

  public validateCoupon(coupon: Coupon) {
    return this.userApi.getCoupon(coupon)
      .pipe(switchMap((c: Coupon) => {
        if (!c.valid) {
          return ErrorObservable.create(null);
        }
        return of(c);
    }));
  }

  /**
   *
   * @return {Promise<T>}
   */
  public logout(): Promise<any> {
    if (!this.isAttemptingLogout()) {
      const actions = this.injector.get(UsersActions);

      //noinspection TypeScriptValidateTypes
      this.ngRedux.dispatch(actions.attemptLogout());
    }

    return new Promise((resolve, reject) => {
      this.ngRedux.select(['user', '_is_attempting_logout']).pipe(filter(loading => !loading),take(1),).subscribe(() => {
        const state = this.ngRedux.getState()['user'];

        if (state?.data?.id) {
            resolve(state['data']);
        } else {
            resolve(null);
        }
      });
    });
  }

  /**
   * Perform refresh token request and return success as observable.
   *
   * @returns {Observable<boolean>}
   */
  public refreshToken(): Observable<boolean> {
    if (!this.isTokenRefreshing() && !this.isAttemptingLogout()) {
      const actions = this.injector.get(UsersActions);
      this.isRefreshingToken = true;
      //noinspection TypeScriptValidateTypes
      this.ngRedux.dispatch(actions.refreshToken());
    }

    return this.ngRedux.select(['user', '_is_refreshing_token']).pipe(
      filter(loading => !loading),
      tap(() => { this.isRefreshingToken = false; }),
      switchMapTo(this.ngRedux.select(['user', '_error'])),
      take(1),
      mergeMap(error => !error ? observableOf(true) : observableThrowError(error)),
      catchError((err: Error) => {
        this.router.navigateByUrl('/auth/logout');
        return observableEmpty();
      }),) as Observable<any>;
  }

  /**
   * Perform active credential switch.
   *
   * @param credential:Credential
   * @returns {Observable<boolean>}
   */
  public switchActiveCredential(credential: Credential): Promise<boolean> {
    if (!this.isSwitchingActiveCredential()) {
      const actions = this.injector.get(UsersActions);
      //noinspection TypeScriptValidateTypes
      this.ngRedux.dispatch(actions.switchActiveCredential(credential));
    }

    return new Promise((resolve, reject) => {
      this.ngRedux.select(['user', '_is_switching_credential']).pipe(filter(loading => !loading),take(1),).subscribe(() => {
        const state = this.ngRedux.getState()['user'];

        resolve(true);
      });
    });
  }

  public impersonate(user: UserAdmin, privileged: boolean = false): Promise<boolean> {
    if (!this.ngRedux.getState()['admin']['user']['impersonating']) {
      const actions = this.injector.get(UserAdminActions);

      //noinspection TypeScriptValidateTypes
      this.ngRedux.dispatch(actions.impersonate(user, privileged));
    }

    return new Promise((resolve, reject) => {
      this.ngRedux.select(['admin', 'user', 'impersonating']).pipe(filter(loading => !loading),take(1),).subscribe(() => {
        resolve(true);
      });
    });
  }

  public isActiveUserOwner(credential: Credential) {
    if (!credential?.owner) {
      return false;
    }

    return this.getUser().id === credential.owner.id;
  }

  public isActiveUserOwnerActiveCredential() {
    return !!this.getUser()['active_credential'] && this.isActiveUserOwner(this.getActiveCredential());
  }

  public hasCheckedCoachingCall(): boolean {
    return this.getUser().coaching_call_viewed;
  }
}
