import { Component, OnInit, Renderer2 } from '@angular/core';
import { PreloaderService } from '@app/core/services/preloader.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LocalStorageService } from '@app/shared/services/local-storage.service';
import * as url from 'url';
import { IntercomService } from './core/services/intercom.service';
import { RudderTrackingService } from './shared/tracking/tracking.service';
import { filter, map } from 'rxjs/operators';
import { SmartLookTrackingService } from '@app/shared/tracking/smartlook.service';
import { RoutingService } from './shared/services/routing.service';

@Component({
  selector: 'st-app',
  template: '<router-outlet></router-outlet>',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private previousBodyClass: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private preloaderService: PreloaderService,
    private intercomService: IntercomService,
    private smartLookTrackingService: SmartLookTrackingService,
    private renderer: Renderer2,
    private rudderTrackingService: RudderTrackingService,
  ) {
  }

  ngOnInit() {
    this.intercomService.init();
    this.rudderTrackingService.init();
    this.smartLookTrackingService.init();

    this.router
      .events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
      ).subscribe((route: ActivatedRoute) => {
        const path = RoutingService.extractPath(route);

        let leafRoute = route;

        // Get tree route
        while (leafRoute.firstChild) {
          leafRoute = leafRoute.firstChild;
        }

        leafRoute.data.subscribe((data: any) => {
          if (data['isLoadingPage'] !== true) {
            this.preloaderService.hide(100);
          } else {
            this.preloaderService.show();
          }
        });

        this.handleBodyClass(path);

        this.rudderTrackingService.pageView(path);
      });

    console.log('%c---', 'color: #FD603D; font-size: 20px');
    console.log('%cStop! ', 'color: #FD603D; font-size: 30px');
    console.log('%cThis is a browser feature intended for developers. If someone told you to copy-paste something here,', 'color: #FD603D; font-size: 16px');
    console.log('%cit is a scam and will give them access to your SellerTools account. ', 'color: #FD603D; font-size: 16px');
    console.log('%c---', 'color: #FD603D; font-size: 20px');

    // Referrer is remembered in case customers registers
    LocalStorageService.rememberReferrer(this.getCorrectReferrer());
  }

  protected getCorrectReferrer() {
    if (this.hasValidUTM(window.location.href, '&')) {
      return window.location.href;
    } else if (!document.referrer.includes('app.seller.tools')) {
      return document.referrer;
    }
    return null;
  }

  protected hasValidUTM(link: string, separator: string) {
    const query = url.parse(link).query || '';
    const utmRegx = new RegExp('((utm_[^=]+)=([^\\' + separator + ']+))', 'gi');
    const matched = query.match(utmRegx) || [];
    return matched.length > 0;
  }

  private handleBodyClass(pathName: string) {
    if (this.previousBodyClass) {
      this.renderer.removeClass(document.body, this.previousBodyClass);
    }

    const newBodyClassName = this.constructBodyClassName(pathName);
    this.renderer.addClass(document.body, newBodyClassName);
    this.previousBodyClass = newBodyClassName;
  }

  private constructBodyClassName(pathName: string): string {
    // Substring removes / on the beginning
    return `st-${pathName.substring(1).replace('/', '-')}`;
  }
}
